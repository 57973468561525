body {
  font-family: sans-serif;
}

.imageresponsive {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.image-gallery-description {
  font-size: 20px;
  bottom: 0 !important;
}

.image-gallery-image,
img {
  max-height: 500px;
  object-fit: contain;
}

@media only screen and (max-width: 600px) {
  .imageresponsive {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
}

.font-comp {
  width: 50px;
  height: 50px;
  border: 1px solid red;
  position: fixed;
  top: 0;
  z-index: 1000;
  right: 0;
}

.font-comp1 {
  width: 50px;
  height: 50px;
  border: 1px solid red;
  position: fixed;
  top: 10;
  z-index: 1000;
  right: 0;
}

.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mysticky {
  display: flex;
  /* flex-flow: column; */
  justify-content: space-around;
  text-align: center;
  color: black;
  font-size: 18px;
  font-weight: 700;
  padding: 2px;
  border-radius: 5px;
  background-color: transparent;
  text-align: center;
}

.plus {
  cursor: pointer;
  margin: 2px;
  text-align: center;
  border-radius: 5px;
}

.minus {
  cursor: pointer;
  margin: 1px;
  text-align: center;
  border-radius: 5px;
}
.nuteral {
  cursor: pointer;
  margin: 2px;
  text-align: center;
  border-radius: 5px;
}
